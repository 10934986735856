import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
import { IFinancingStaticDataConfig } from '../modules/financing-static-data/financing-static-data.props.autogenerated';
export interface IFinancingState {
    financingStaticData: IFinancingStaticDataConfig;
}

/**
 * FinancingState - action input
 */
export class FinancingStateInput implements IActionInput {
    public getCacheKey = () => `FinancingStaticData`;
    public getCacheObjectType = () => 'FinancingStaticData';
    public dataCacheType = (): CacheType => 'request';
}
export async function financingStateAction(input: FinancingStateInput, ctx: IActionContext): Promise<IFinancingState> {
    return { financingStaticData: {} };
}

const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    return new FinancingStateInput();
};

export default createObservableDataAction<IFinancingState>({
    action: <IAction<IFinancingState>>financingStateAction,
    input: createInput
});
